import React, { Component } from 'react'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import Title from '../components/page-title'
import { Flex, Box } from '@rebass/grid'
import Row from '../components/page-content-row'
import SEO from '../components/seo'
import Copy from '../components/page-copy'
import PageFacadesGrid from '../components/page-facades-grid'
import Image1 from '../images/designs/happyhaus-1A-form-bold.jpg'
import Image2 from '../images/designs/happyhaus-1A-form-mint.jpg'
import Image3 from '../images/designs/happyhaus-1B-form-warm.jpg'
import Image4 from '../images/designs/happyhaus-1C-form-warm.jpg'

const ogImages = [
  `https://happyhaus.com.au${Image1}`,
  `https://happyhaus.com.au${Image2}`,
  `https://happyhaus.com.au${Image3}`,
  `https://happyhaus.com.au${Image4}`,
]

export default class Forms extends Component {
  render() {
    const { data } = this.props
    const facadesData = data.facades.edges
    const imagesData = data.images.edges

    return (
      <>
        <SEO
          title="Facades"
          description="Our facades are more than just a pretty face. The one you choose informs the entire shape or silhouette of your home from front to back."
          images={ogImages}
        />
        <Row>
          <Flex>
            <Box width={1} px={2} mr={[0, '20%']}>
              <Title align="left">
                <p>
                  Our facades are more than just a pretty face. The one you
                  choose informs the entire shape or silhouette of your home
                  from front to back. Happy Haus facades can adapt across our
                  home design collection.
                </p>
              </Title>
              <Copy>
                <p>
                  <Link to="/forms-single">Single Storey</Link> &nbsp;{' '}
                  <Link to="/forms-double">Double Storey</Link>
                </p>
              </Copy>
            </Box>
          </Flex>
        </Row>
        <Row>
          <PageFacadesGrid facades={facadesData} images={imagesData} />
        </Row>
      </>
    )
  }
}

export const query = graphql`
  query {
    facades: allFacadesJson {
      edges {
        node {
          id
          title
          image
          storey
        }
      }
    }

    images: allFile {
      edges {
        node {
          relativePath
          publicURL
        }
      }
    }
  }
`
